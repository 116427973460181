import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start py-3 mt-5 mb-5">
    <Link to="/"><img alt="HiStartup Logo" src={require('../images/Primary.svg')}></img></Link>
    <div className="text-center text-md-right mt-4 mt-md-0 text-high footer-links">
      <p><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
      <p><Link to="/privacy-policy">Privacy Policy</Link></p>
      <p><Link to="/cookie-policy">Cookie Policy</Link></p>
      <p className="small mb-0 mt-4 mt-md-5">© Copyright HiStartup {new Date().getFullYear()}</p>
    </div>
  </div>
  )
}

export default Footer;
